<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 481.75 482.18"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M26.61,482.18,0,455.54,148.88,306.66c-39.09-51.37-52.48-107.75-35.27-169.53,13.33-47.83,42-84.94,84.93-110.56C278.05-20.88,378.67-3.37,437.61,67.83c61.9,74.77,58.28,178.49-8.26,248.87-63.1,66.74-170.26,81.95-253.65,16.39ZM443.94,187.47c-.28-82.81-67.8-150.08-150.32-149.75C210.61,38.05,143.51,105.45,144,188c.49,82.77,67.56,149.72,149.91,149.65C376.44,337.6,444.22,269.74,443.94,187.47Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconSearch2',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
